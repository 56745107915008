import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1097.000000 1097.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1097.000000) scale(0.100000,-0.100000)">
					<path d="M0 9970 c0 -604 4 -1000 9 -1000 8 0 201 81 296 125 17 7 65 27 107
44 42 17 96 39 120 50 24 11 201 87 393 170 193 82 449 193 570 246 121 53
262 115 313 136 51 22 101 44 110 49 32 18 157 72 232 100 78 30 294 125 465
205 55 26 123 55 150 65 57 20 264 110 377 164 42 20 79 36 82 36 3 0 33 13
68 29 35 16 119 54 188 84 69 31 177 79 240 106 63 28 133 60 155 71 22 10 83
38 135 61 52 22 165 73 250 111 85 39 182 83 215 97 33 15 71 32 85 38 19 9
-557 12 -2267 12 l-2293 1 0 -1000z"/>
<path d="M2133 7018 c-5 -7 -20 -38 -33 -68 -12 -30 -30 -66 -39 -80 -27 -41
-52 -101 -72 -175 -31 -113 -50 -184 -54 -202 -2 -10 -13 -49 -24 -87 -11 -37
-25 -102 -31 -144 -6 -41 -20 -114 -31 -161 -21 -86 -24 -102 -54 -261 -8 -47
-19 -94 -24 -105 -5 -11 -12 -33 -14 -50 -14 -89 -27 -146 -41 -179 -8 -20
-25 -78 -37 -129 -12 -50 -30 -125 -40 -166 -11 -40 -18 -76 -15 -78 2 -3 0
-19 -5 -36 -6 -18 -12 -41 -14 -52 -11 -51 -29 -111 -37 -122 -16 -20 -33
-190 -22 -215 32 -71 58 -118 65 -118 5 0 9 -7 9 -15 0 -8 12 -34 28 -57 29
-47 123 -180 132 -188 3 -3 14 -21 24 -40 11 -19 29 -44 39 -55 11 -11 28 -39
38 -62 11 -23 23 -43 27 -45 9 -4 62 -75 62 -83 0 -4 19 -30 43 -58 43 -51
167 -231 197 -284 8 -15 18 -32 23 -38 15 -21 67 -90 75 -100 4 -5 28 -39 52
-75 25 -36 48 -66 53 -68 4 -2 7 -8 7 -14 0 -6 10 -25 23 -42 13 -17 45 -67
72 -111 27 -44 54 -84 60 -90 6 -5 24 -30 40 -55 15 -25 44 -65 64 -89 19 -24
36 -49 37 -56 1 -7 10 -22 20 -33 34 -37 44 -50 70 -99 15 -26 39 -66 53 -87
14 -21 31 -48 38 -60 7 -11 18 -25 25 -31 8 -5 38 -44 67 -85 30 -41 57 -77
61 -80 19 -15 50 -102 50 -141 0 -36 -6 -51 -25 -69 -14 -13 -25 -28 -25 -33
0 -5 -16 -24 -35 -43 -19 -18 -35 -37 -35 -43 0 -6 -9 -11 -19 -11 -12 0 -25
-12 -33 -30 -7 -16 -19 -30 -25 -30 -19 0 -15 -32 6 -56 23 -28 75 -29 150 -2
31 11 67 22 81 25 14 3 56 27 94 52 111 73 120 78 239 127 61 25 164 72 227
104 232 118 253 122 356 74 33 -15 113 -52 179 -84 66 -31 139 -65 163 -75 73
-33 64 -36 -177 -60 -85 -8 -181 -15 -215 -15 -76 0 -99 -12 -58 -29 15 -7 58
-40 94 -74 109 -104 148 -136 234 -192 46 -30 85 -55 88 -55 3 0 25 -13 49
-30 25 -16 50 -30 56 -30 6 0 11 -4 11 -9 0 -5 15 -16 33 -25 17 -9 46 -25 62
-36 17 -10 62 -37 102 -60 39 -22 98 -60 130 -83 32 -24 80 -56 106 -72 27
-15 84 -51 126 -79 43 -28 96 -62 119 -76 23 -14 42 -31 42 -38 0 -7 13 -18
30 -23 16 -6 32 -19 35 -29 5 -15 14 -18 54 -15 26 2 68 14 92 26 43 23 342
235 405 288 28 24 278 218 343 266 14 11 51 40 82 65 30 25 70 56 89 70 72 53
385 294 390 299 3 3 42 34 88 69 203 154 253 192 353 272 59 47 142 112 185
145 43 33 82 65 88 70 6 6 22 18 36 27 14 10 57 43 95 73 39 31 95 73 125 94
30 22 78 59 106 83 61 52 82 69 254 199 218 165 416 319 501 388 31 25 75 61
98 79 49 40 50 52 22 204 -22 121 -52 293 -81 468 -37 224 -48 267 -78 286
-15 10 -32 23 -39 29 -26 26 -83 126 -127 225 -156 344 -233 433 -395 459
-169 27 -446 -93 -566 -246 -48 -62 -60 -96 -50 -142 5 -23 16 -33 49 -46 l42
-16 75 45 c42 26 81 46 88 46 6 0 11 3 11 8 0 11 109 51 161 58 44 6 109 -14
109 -34 0 -5 -33 -18 -73 -30 -129 -38 -272 -154 -328 -267 -41 -85 -49 -181
-20 -248 l21 -49 -21 -29 c-11 -16 -31 -32 -45 -35 -46 -12 -71 4 -112 69 -39
62 -145 266 -175 339 l-15 37 69 26 c38 15 71 31 75 36 8 14 -29 171 -43 176
-6 2 -40 -7 -75 -21 -108 -43 -107 -44 -156 56 l-44 88 -41 -29 c-61 -42 -80
-67 -116 -156 -19 -44 -56 -128 -84 -187 -46 -98 -49 -109 -38 -136 8 -20 22
-32 40 -35 48 -10 112 -68 146 -132 17 -33 59 -114 94 -180 77 -147 166 -244
247 -268 99 -29 183 -2 362 116 86 57 93 64 90 92 -3 28 -1 30 32 28 139 -7
172 -4 225 21 54 25 133 75 165 105 9 9 31 29 47 44 l31 29 43 -28 c24 -15 46
-31 47 -35 2 -5 9 -9 15 -9 23 0 97 -35 97 -46 0 -15 -180 -158 -305 -243 -27
-19 -88 -62 -135 -97 -196 -144 -429 -318 -605 -449 -103 -77 -206 -153 -229
-169 -22 -16 -99 -73 -171 -126 -71 -53 -170 -125 -219 -161 -166 -120 -371
-273 -384 -286 -8 -7 -23 -18 -35 -25 -12 -6 -91 -63 -177 -127 -85 -65 -200
-148 -255 -186 -55 -38 -116 -81 -135 -97 -44 -36 -76 -36 -308 -2 -266 38
-491 85 -817 168 -77 20 -148 36 -157 36 -22 0 -23 9 -2 25 15 10 5 18 -67 57
-46 25 -151 78 -234 119 -146 70 -231 118 -500 279 -71 43 -150 88 -174 100
l-44 22 7 119 c8 140 31 324 47 380 11 33 18 40 59 54 67 22 192 88 246 129
26 20 50 36 54 36 12 0 119 113 155 164 19 26 47 80 62 120 23 59 27 87 28
166 0 80 -4 102 -23 140 -51 97 -172 191 -273 211 -32 6 -59 13 -59 14 0 1 12
29 27 61 83 182 -1 437 -179 546 -88 54 -189 75 -293 60 -44 -7 -90 -15 -102
-18 -13 -4 -23 -2 -23 2 0 7 58 91 97 139 4 6 28 37 53 70 72 97 156 193 256
297 97 98 414 404 493 474 25 21 54 48 65 59 12 12 86 64 166 116 80 52 155
102 167 110 46 31 210 150 238 173 16 14 27 28 23 31 -6 6 -158 14 -818 40
-212 9 -482 20 -600 25 -507 22 -696 31 -880 40 -243 12 -226 12 -237 -2z
m651 -1042 c48 -1 81 -6 83 -13 5 -13 -23 -127 -67 -283 -42 -149 -100 -385
-100 -409 0 -13 -19 -31 -59 -55 -41 -25 -61 -44 -66 -63 -11 -45 -14 -192 -4
-198 16 -10 10 -38 -6 -31 -11 4 -15 -2 -15 -27 0 -18 -16 -72 -36 -122 -47
-118 -44 -109 -105 -264 -29 -74 -55 -129 -57 -123 -12 38 24 356 78 682 91
547 130 742 177 892 l14 47 42 -15 c23 -9 78 -17 121 -18z m483 -907 c59 -22
88 -81 78 -161 -8 -58 -28 -77 -129 -123 -106 -49 -126 -50 -148 -3 -16 34
-98 246 -98 254 0 2 13 1 28 -2 15 -3 55 2 87 11 111 31 120 33 137 34 9 1 29
-4 45 -10z m-647 -224 c5 -3 10 -14 10 -24 0 -10 16 -51 36 -92 20 -41 44 -94
54 -119 10 -25 41 -91 69 -148 28 -56 61 -128 72 -160 12 -31 34 -84 50 -118
16 -33 29 -66 29 -72 0 -7 4 -20 9 -30 14 -26 36 -79 66 -162 37 -103 87 -224
96 -230 11 -9 40 -71 54 -117 14 -46 31 -151 27 -166 -1 -4 4 -33 12 -65 9
-31 13 -58 11 -60 -3 -4 -35 64 -35 74 0 2 -54 113 -121 246 -66 134 -143 299
-170 368 -27 69 -57 141 -66 160 -69 151 -190 485 -240 663 -20 73 -22 91 -11
104 11 14 14 12 25 -15 7 -17 17 -33 23 -37z m5316 -42 c62 -68 51 -145 -27
-187 -46 -25 -81 -16 -130 33 -73 72 -63 155 21 177 79 21 99 18 136 -23z
m-4370 -338 c66 -50 86 -139 48 -204 -31 -52 -240 -145 -263 -117 -5 6 -32 71
-60 145 -61 158 -62 154 55 180 128 28 180 27 220 -4z"/>
<path d="M7682 6970 c-123 -17 -284 -80 -397 -156 -112 -76 -182 -158 -232
-276 -31 -73 -34 -90 -29 -141 6 -62 41 -140 78 -174 12 -12 48 -33 78 -47 73
-34 140 -34 355 4 239 42 298 39 339 -16 72 -99 -186 -224 -461 -224 l-84 0
-15 -37 c-9 -20 -21 -62 -26 -93 -12 -62 -33 -100 -47 -84 -5 5 -36 61 -69
124 -33 63 -83 158 -112 210 -112 203 -142 259 -202 373 -34 64 -68 117 -75
117 -7 0 -27 -6 -45 -14 -18 -8 -52 -21 -75 -30 -132 -50 -143 -60 -143 -140
0 -57 16 -99 99 -270 140 -286 152 -321 129 -377 -22 -52 -97 -99 -159 -99
-120 0 -148 32 -315 366 -75 151 -138 274 -140 274 -12 0 -167 -69 -213 -95
-62 -34 -75 -61 -67 -138 6 -60 127 -309 240 -497 58 -95 111 -165 143 -188
81 -58 226 -78 334 -46 62 18 169 81 281 166 85 64 108 71 155 43 39 -23 65
-14 158 56 36 27 74 53 85 59 12 6 20 21 20 36 0 23 2 24 23 12 14 -8 61 -12
127 -12 85 0 122 6 192 27 105 32 289 119 378 178 99 67 199 173 239 253 30
63 33 74 29 145 -2 46 -11 91 -22 113 -30 62 -92 124 -153 154 -90 44 -175 44
-401 0 -217 -43 -266 -45 -283 -15 -23 44 7 107 66 137 30 14 147 39 255 52
52 7 107 19 123 26 24 13 27 20 27 68 0 29 7 73 15 97 14 40 14 44 -2 60 -23
22 -116 30 -201 19z"/>
<path d="M5459 5971 c-107 -35 -249 -139 -310 -228 -16 -23 -34 -43 -40 -43
-5 0 -14 12 -20 27 -6 16 -17 35 -25 44 -14 15 -20 15 -72 -11 -31 -15 -82
-39 -113 -54 -66 -31 -85 -63 -75 -125 5 -35 51 -146 91 -224 17 -32 14 -31
-60 24 -61 45 -151 91 -233 118 -174 59 -402 -2 -554 -147 -76 -72 -111 -129
-142 -232 -43 -144 -47 -294 -8 -336 10 -10 46 -60 80 -110 69 -101 92 -125
169 -177 89 -60 139 -72 298 -72 120 0 149 4 203 23 139 50 289 180 350 304
38 77 56 181 47 268 l-7 65 50 -82 c27 -44 62 -111 77 -148 31 -72 63 -105
104 -105 34 0 111 38 191 96 76 54 76 57 23 159 -242 466 -258 516 -180 564
18 11 58 24 89 29 47 7 62 5 104 -13 96 -43 135 -98 291 -405 34 -69 73 -133
85 -144 38 -31 90 -16 216 59 97 59 111 71 106 89 -10 32 -77 164 -171 336
-198 361 -287 449 -463 456 -41 2 -87 0 -101 -5z m-974 -804 c60 -18 146 -110
175 -187 32 -86 32 -137 -2 -187 -50 -76 -154 -83 -285 -18 -123 61 -179 153
-171 281 5 73 20 96 84 120 42 15 128 12 199 -9z"/>
<path d="M6265 4686 c-206 -45 -445 -204 -487 -324 -10 -27 -18 -52 -18 -55 0
-4 14 -22 31 -42 41 -47 80 -47 140 -2 24 18 60 39 79 47 19 8 40 19 45 25 24
28 181 67 230 57 22 -4 15 -9 -47 -36 -181 -79 -327 -218 -375 -359 -55 -158
33 -334 197 -394 77 -29 222 -28 252 0 7 7 29 19 48 28 34 16 198 145 232 182
16 18 19 18 65 3 44 -15 51 -15 95 1 27 9 48 21 48 25 0 4 6 8 13 8 15 0 167
102 167 113 0 4 -29 53 -63 110 -35 56 -97 165 -137 242 -122 235 -170 293
-286 347 -74 35 -146 42 -229 24z m142 -596 c58 -35 50 -137 -14 -184 -38 -28
-127 -34 -169 -11 -61 33 -83 122 -41 169 41 47 167 61 224 26z"/>
<path d="M4905 4399 c-78 -23 -119 -40 -353 -146 -90 -41 -173 -78 -185 -83
-12 -4 -71 -31 -130 -59 -60 -28 -112 -51 -116 -51 -5 0 -20 -9 -33 -20 -35
-27 -43 -87 -23 -165 16 -61 122 -283 175 -365 46 -71 80 -132 80 -141 0 -5
18 -38 40 -74 22 -36 40 -70 40 -75 0 -6 4 -10 8 -10 5 0 18 -19 31 -42 12
-24 26 -50 32 -58 9 -15 77 -140 145 -268 42 -79 89 -114 148 -111 42 3 67 13
100 39 10 8 20 14 22 13 9 -4 51 12 99 38 29 15 55 26 57 23 3 -2 11 2 18 11
7 8 17 15 23 15 10 0 43 14 143 61 23 10 48 19 56 19 9 0 21 6 27 14 7 8 38
27 69 42 31 15 59 30 62 34 3 3 28 22 55 40 107 73 240 225 283 324 25 55 52
191 52 257 0 120 -56 280 -143 409 -41 60 -150 173 -201 207 -23 15 -43 31
-46 34 -9 12 -133 71 -179 86 -26 8 -100 16 -166 19 -100 3 -132 0 -190 -17z
m238 -387 c51 -23 56 -27 120 -87 105 -98 177 -261 177 -401 0 -78 -15 -115
-65 -154 -60 -48 -392 -210 -430 -210 -18 0 -28 16 -191 295 -149 256 -223
394 -224 416 0 9 10 20 23 26 173 80 273 121 332 137 11 3 63 3 115 2 69 -3
108 -9 143 -24z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
